@charset "UTF-8";
/* You can add global styles to this file, and also import other style files */
/*@font-face {
  font-family: "Itim_Regular";
  src: url("/src/app/font/Itim-Regular.ttf");
}*/
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/********** NATIF *********/
button {
  color: white;
  border: 1px solid #3b4d62;
  background-color: #3b4d62;
  border-radius: 1rem;
  padding: 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  cursor: pointer;
}

button:disabled {
  background-color: rgba(255, 0, 0, 0.3);
  cursor: not-allowed;
  border: 1px solid gray;
}

label {
  display: block;
}

input {
  border-color: #3b4d62;
  border-radius: 0.5rem;
  padding: 5px;
  margin-bottom: 1rem;
  width: 70%;
  max-width: 500px;
}

input:read-only {
  background-color: #999;
}

/********** Fin NATIF *********/
/********** Générique *********/
.center {
  text-align: center;
}

.cursorPointer {
  cursor: pointer;
}

.error,
.red {
  margin: 0.5rem;
  color: red;
  text-shadow: #000 0 0 0.5rem;
}

.green {
  margin: 0.5rem;
  /*color: #07c007bb;*/
  color: #ddd;
  text-shadow: #000 0 0 0.5rem;
}

.orange {
  margin: 0.5rem;
  color: orange;
  text-shadow: #000 0 0 0.5rem;
}

.size2 {
  font-size: 2rem;
}

.bg-red {
  background: rgba(255, 0, 0, 0.4);
}

.bg-1 {
  background-color: rgba(20, 20, 20, 0.4);
  border-radius: 1rem;
  border: 1px solid #3b4d62;
  margin-right: 1rem;
  margin-left: 1rem;
  padding: 0.5rem;
}

/********** Fin Générique *********/
body {
  font-family: Itim, sans-serif;
}

#bg_img {
  position: fixed;
  background: #555 url("https://trihdf.prolivesport.fr/pict/app_bg01.jpg") center center/cover;
  width: 100%;
  height: 130vh;
}

#main {
  position: absolute;
  z-index: 2;
  display: flex;
  flex-direction: column;
  /*background: #555
    url("https://media.istockphoto.com/photos/open-water-swimmers-swimming-front-crawl-in-sea-picture-id1071375798?k=6&m=1071375798&s=612x612&w=0&h=YBSQcAUmxta_vu0yDeee7voVqQIpdnxvCR92Xcx5i1c=")
    center center/cover;*/
  background-repeat: repeat;
  background-position: fixed;
  height: 100vh;
  width: 100%;
}

app-header {
  color: white;
  font-size: 3rem;
  text-align: center;
  margin-bottom: 1.5rem;
}

#headerTitle {
  color: white;
  text-shadow: #3b4d62 0.2em 0.2em 0.3em;
  width: 80%;
}

#headerLogo {
  width: 20%;
}

@media screen and (max-width: 1400px) {
  app-header {
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 1200px) {
  /** {
    font-size: 28px;
  }*/
  app-header {
    font-size: 2rem;
  }
}
@media screen and (max-width: 950px) {
  /** {
    font-size: 22px;
  }*/
  app-header {
    width: 100%;
    font-size: 2rem;
  }

  #headerLogo {
    display: none;
  }

  #headerTitle {
    width: 100%;
  }
}
@media screen and (max-width: 650px) {
  * {
    font-size: 18px;
  }

  app-header {
    font-size: 1.2rem;
  }

  .size2 {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 400px) {
  * {
    font-size: 16px;
  }

  app-header {
    font-size: 1.2rem;
  }
}
/********** FOOTER *********/
#footerLink {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  flex-flow: wrap;
  margin: 0;
  padding: 0;
}

#footerLink li {
  padding: 10px;
  margin-left: 100px;
  margin-right: 100px;
  width: 200px;
  list-style: none;
}

#footerLink img {
  width: 100%;
}

/********** end FOOTER *********/
/********** SITE LIST *********/
app-site-list {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  flex-flow: wrap;
}

app-site-detail {
  flex-basis: 300px;
  height: 200px;
  padding: 0.5rem;
  margin: 1rem;
  text-align: center;
  color: black;
  text-shadow: white 0.1em 0.1em 0.2em;
  font-weight: bold;
}

app-site-detail div {
  background-position: center center;
  border-radius: 1.5rem;
  padding: 10px;
  background-size: auto 120%;
  border: 1px solid #777;
  box-shadow: #3b4d62 0rem 0rem 1.5rem;
  cursor: pointer;
}

app-site-detail div:hover {
  opacity: 0.7;
}

app-site-detail span.siteDetailAddress {
  font-size: 1.2rem;
  color: black;
  text-shadow: white 0em 0em 0.3em;
  display: block;
  height: 1.5rem;
}

app-site-detail span.siteDetailLocation {
  font-size: 2rem;
  color: white;
  text-shadow: black 0.1em 0.1em 0.4em;
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 4rem;
}

app-site-detail span.siteDetailDep {
  font-size: 2.5rem;
  color: #252525;
  text-shadow: white 0.1em 0.1em 0.4em;
  display: block;
}

button.siteSelect {
  color: #3b4d62;
  text-shadow: white 0.1em 0.1em 0.2em;
  background-color: transparent;
  padding: 0.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  border: 0;
}

@media screen and (max-width: 650px) {
  button.siteSelect {
    font-size: 1.2rem;
  }
}
/********** End SITE LIST *********/